import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

/**
 * Only scroll the content of the modal.
 */
const ContentScollModal = styled(Modal)`
    height: calc(100% - 14px - 5rem) !important;

    > .content {
        height: calc(100% ${props => (!Object.keys(props).includes('hasHeader') || (Object.keys(props).includes('hasHeader') && props.hasHeader)) && `- 65px`} ${props => props.hasActions && `- 65px`});

        overflow-y: scroll;
    }

    > .actions {
        display: flex;
    }
`;

export default ContentScollModal;
