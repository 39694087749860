import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Form, Header } from 'semantic-ui-react';
import { Body, ContentContainer, Content, Toolbar, Link } from 're-cy-cle';
import RightDivider from 'spider/component/RightDivider';
import { EntityStore } from 'store/Entity';
import { FuelCost } from 'store/FuelCost';
import { CostSupplierStore } from 'store/CostSupplier';
import { CostProductStore } from 'store/CostProduct';
import { TruckStore } from 'store/Truck';
import { DriverStore } from 'store/Driver';
import { LedgerCostStore } from 'store/LedgerCost';
import { LedgerVatStore } from 'store/LedgerVat';
import { FormLabel, ErrorLabel, TargetNumberInput, TargetSelect, TargetRadioButtons, TargetTextInput } from 'spider/semantic-ui/Target';
import TargetMoneyInput from 'component/TargetMoneyInput';
import TargetCurrencySelect from 'component/TargetCurrencySelect';
import { showSaveNotification } from 'helpers/notification';
import { EditButton, CancelButton, SaveButton, IconButton } from 'spider/semantic-ui/Button';
import { Modal, Popup, Loader } from 'semantic-ui-react';
import DatetimePicker from 'component/form/DatetimePicker';
import { fetchLocationByTruck } from 'store/Location';
import LocationEditFreeForm from 'container/LocationEditFreeForm';
import { showNotification } from 'helpers/notification';
import Activity, { Container, BACKGROUND_COLOR } from 'component/Activity';
import { IconSide, ContentSide } from 'component/Compact/ActivityBlock';
import styled from 'styled-components';
import ModalActivityFinalize from 'screen/ModalActivityFinalize';
import { getViewStore } from 'helpers/viewStore';
import DeclarationSeparatedDraw from 'container/Declaration/DeclarationSeparatedDraw';
import { AssignmentStore } from 'store/Assignment';
import { SERVER_DATETIME_FORMAT } from 'helpers';
import moment from 'moment';
import { User } from 'store/User';
import { Declaration } from 'store/Declaration';

const StyledContainer = styled(Container)`
    justify-content: center;
    align-items: center;
`;

export const REQUIRED_RELATIONS = [
    'entity',
    'costInvoice',
    'costSupplier',
    'costProduct',
    'truck.entity',
    'driver',
    'ledgerCost',
    'ledgerCost.codeReports',
    'ledgerVat',
    'location',
    'activity.location',
    'declaration',

    'declaration.costProduct',
    'declaration.driver',
    'declaration.roadCost.truck.entity',
    'declaration.fuelCost.truck.entity',
    'declaration.roadCost.entity',
    'declaration.fuelCost.entity',
    'declaration.approvedBy',
    'declaration.rejectedBy',
    'declaration.fuelCost.truck',
    'declaration.roadCost.truck',
    'declaration.fuelCost.costProduct',
    'declaration.roadCost.costProduct',
    'declaration.rejectionReason',
    'declaration.roadCost.ledgerCost.codeReports',
    'declaration.fuelCost.ledgerCost.codeReports',
    'declaration.roadCost.activity.location',
    'declaration.roadCost.driver',
    'declaration.roadCost.location',
    'declaration.fuelCost.activity.location',
    'declaration.fuelCost.driver',
    'declaration.fuelCost.location',
    'declaration.roadCost.costInvoice',
    'declaration.fuelCost.costInvoice',
    'declaration.documents',
    'declaration.simpledTransaction',
];

@observer
export default class FuelCostEdit extends Component {
    static propTypes = {
        fuelCost: PropTypes.instanceOf(FuelCost).isRequired,
        afterSave: PropTypes.func.isRequired,
        withDeclaration: PropTypes.bool.isRequired,
        currentUser: PropTypes.instanceOf(User).isRequired,
        declaration: PropTypes.instanceOf(Declaration).isRequired,

        /**
         * Hide entity field. Useful when entity is given, and not needed
         * to fill in.
         */
        showEntity: PropTypes.bool.isRequired,

        /**
         * Hide supplier field. Useful when supplier is given, and not needed
         * to fill in.
         */
        showSupplier: PropTypes.bool.isRequired,

        /**
         * Disable netOc / netFc input fields. Also let net follow gross.
         */
        disableNet: PropTypes.bool.isRequired,

        /**
         * T25643: ledger vat calculation depends on entity and cost supplier.
         * In modal vue there is neither entity nor cost supplier.
         */
        getLedgerVat: PropTypes.bool.isRequired,

        /**
         * T25861
         */
        showHaveReceipt: PropTypes.bool.isRequired,
        disableDeclarationForCostInvoice: PropTypes.bool.isRequired,
    };

    /**
     * Defaults should match how an edit without a cost invoice / non modal
     * should render.
     */
    static defaultProps = {
        showEntity: false,
        showSupplier: false,
        disableNet: true,
        getLedgerVat: false,
        showHaveReceipt: true,
        disableDeclarationForCostInvoice: false,
    };
    @observable updateTruck = true;

    @observable entityStore = new EntityStore({ params: { order_by: 'name' } });
    @observable costSupplierStore = new CostSupplierStore({ params: { order_by: 'name' } });
    @observable costProductStore = new CostProductStore({
        relations: ['entities'],
        params: {
            '.cost_type': 'fuel',
            order_by: 'description',
        }
    });
    @observable driverStore = new DriverStore({ params: { order_by: 'name' } });
    @observable truckStore = new TruckStore({
        relations: ['entity'],
        params: { order_by: 'license_plate' },
        limit: null,
    });
    @observable ledgerCostStore = new LedgerCostStore({
        relations: ['entity', 'costProduct', 'codeReports', 'currentCodeReport'],
        params: { order_by: 'ledger_code_accounting' },
        limit: null,
    });
    @observable ledgerVatStore = new LedgerVatStore({
        relations: ['entity'],
        params: { order_by: 'ledger_code' },
        limit: null,
    });
    @observable fetchingActivity = false;

    @observable isFirstDriver = true;

    constructor(...args) {
        super(...args);
        this.save = this.save.bind(this);
    }

    save() {
        const { fuelCost, afterSave } = this.props;

        const isNew = fuelCost.isNew;

        if (fuelCost.declaration.id && !fuelCost.isNew){
            fuelCost.declaration.save()
        }

        return fuelCost
            .save({ onlyChanges: true })
            .then(showSaveNotification)
            .then(afterSave)
            .then(()=>{
                if(isNew){
                    showNotification({
                        type: 'info',
                        dismissAfter: 4000,
                        message: t('fuelCost.previousId', { id: fuelCost.id }),
                    });
                }
            })
    };

    saveNew(fuelCost) {
        return this.save()
                .then(() => fuelCost.clear())
    }

    saveAndFinalize(fuelCost) {
        if (fuelCost.isNew) {
            return this.save()
                .then(() => fuelCost.finalize())
                .then(() => fuelCost.clear());
        } else {
            return this.save()
                .then(() => fuelCost.finalize())
        }
    }

    @observable updateId = null;

    componentDidUpdate() {
        const { fuelCost, withDeclaration, declaration } = this.props;

        if (withDeclaration && this.updateId !== declaration.id) {

            if ((fuelCost.activity.deleted || !fuelCost.activity.id) && withDeclaration) {
                this.findLocation()
            }

            if (!fuelCost.ledgerCost.id) {
                this.findLedgerCost();
            }
            this.updateId = declaration.id
        }
    }

    componentDidMount() {
        const { fuelCost, withDeclaration, declaration } = this.props;

        if (withDeclaration && declaration.id) {
            this.updateId = declaration.id
        }
        if ((fuelCost.activity.deleted || !fuelCost.activity.id) && withDeclaration) {
            this.findLocation()
        }

        if (!fuelCost.ledgerCost.id) {
            this.findLedgerCost();
        }
    }

    findLocation = () => {
        const { fuelCost, withDeclaration, declaration } = this.props;
        if (withDeclaration && !fuelCost.driver.id) {
            fuelCost.setInput('driver', declaration.driver)
        }

        this.fetchingActivity = true;

        if (fuelCost.location) {
            fuelCost.location.clear();
        }

        let assignment = null;

        const assignment_as_driver1 = new AssignmentStore({
            relations: ['truck.entity', 'driver1'],
            limit: 1,
            params: {
                order_by: 'activated_at',
                '.driver1': fuelCost.driver ? fuelCost.driver.id : null,
                '.activated_at:lte': fuelCost.transactionDatetime ? fuelCost.transactionDatetime.format(SERVER_DATETIME_FORMAT) : moment().format(SERVER_DATETIME_FORMAT),
                '.activated_at:gte': fuelCost.transactionDatetime ? fuelCost.transactionDatetime.format(SERVER_DATETIME_FORMAT) : moment().format(SERVER_DATETIME_FORMAT),
            }
        })

        const assignment_as_driver2 = new AssignmentStore({
            relations: ['truck.entity', 'driver2'],
            limit: 1,
            params: {
                order_by: 'activated_at',
                '.driver2': fuelCost.driver ? fuelCost.driver.id : null,
                '.activated_at:lte': fuelCost.transactionDatetime ? fuelCost.transactionDatetime.format(SERVER_DATETIME_FORMAT) : moment().format(SERVER_DATETIME_FORMAT),
                '.activated_at:gte': fuelCost.transactionDatetime ? fuelCost.transactionDatetime.format(SERVER_DATETIME_FORMAT) : moment().format(SERVER_DATETIME_FORMAT),
            }
        })

        const assignment_as_driver1_without_finished = new AssignmentStore({
            relations: ['truck.entity', 'driver1'],
            limit: 1,
            params: {
                order_by: 'activated_at',
                '.driver1': fuelCost.driver ? fuelCost.driver.id : null,
                '.activated_at:lte': fuelCost.transactionDatetime ? fuelCost.transactionDatetime.format(SERVER_DATETIME_FORMAT) : moment().format(SERVER_DATETIME_FORMAT),
                '.finished_at:isnull': true,
            }
        })

        const assignment_as_driver2_without_finished = new AssignmentStore({
            relations: ['truck.entity', 'driver2'],
            limit: 1,
            params: {
                order_by: 'activated_at',
                '.driver2': fuelCost.driver ? fuelCost.driver.id : null,
                '.activated_at:lte': fuelCost.transactionDatetime ? fuelCost.transactionDatetime.format(SERVER_DATETIME_FORMAT) : moment().format(SERVER_DATETIME_FORMAT),
                '.finished_at:isnull': true,
            }
        })

        Promise.all([
            assignment_as_driver1.fetch(),
            assignment_as_driver2.fetch(),
            assignment_as_driver1_without_finished.fetch(),
            assignment_as_driver2_without_finished.fetch(),
        ])
        .then(()=>{
            if (assignment_as_driver1.length === 1) {
                this.isFirstDriver = true;
                assignment = assignment_as_driver1.at(0)
            } else if (assignment_as_driver2.length === 1){
                this.isFirstDriver = false;
                assignment = assignment_as_driver2.at(0)
            } else if (assignment_as_driver1_without_finished.length === 1){
                this.isFirstDriver = true;
                assignment = assignment_as_driver1_without_finished.at(0)
            } else if (assignment_as_driver2_without_finished.length === 1){
                this.isFirstDriver = false;
                assignment = assignment_as_driver2_without_finished.at(0)
            }

            if (assignment) {
                if (assignment.truck.id && this.props.withDeclaration){
                    if (this.updateTruck && !declaration.truck.id) {
                            this.updateTruck = false;
                            fuelCost.setInput('truck', assignment.truck);
                    } else if (declaration.truck.id && this.updateTruck) {
                        this.updateTruck = false;
                        fuelCost.setInput('truck', declaration.truck)
                    }
                    fuelCost.setInput('entity', assignment.truck.entity);
                    this.findLedgerCost();
                }
            }
            fetchLocationByTruck(fuelCost.truck.id, fuelCost.transactionDatetime, true, this.props.withDeclaration, fuelCost.driver, this.isFirstDriver)
            .then(({ activity, location }) => {
                if (activity) {
                    fuelCost.setInput('activity', activity);
                    if (!this.props.withDeclaration){
                        fuelCost.setInput('driver', activity.assignment.driver1);
                    }
                }

                if (location) {
                    fuelCost.setInput('location', location);
                }
            })
            .catch(() => {}).then(() => this.fetchingActivity = false);
        })
    }

    /**
     * Ledger Cost can be automatically attached based on entity and truck
     * category.
     */
    findLedgerCost = () => {
        const { fuelCost } = this.props;
        this.ledgerCostStore.fetch()
        .then( ()=> {
            fuelCost.setLedgerCost(this.ledgerCostStore);
        })
    }

    /**
     * Ledger Cost can be automatically attached based on entity and country.
     */
    findLedgerVat = () => {
        const { fuelCost } = this.props;

        // T25630: Ledger VAT can be linked to transaction only if transaction is linked to the Invoice.
        if(fuelCost.costInvoice.id){
            this.ledgerVatStore.fetch()
            .then( ()=> {
                fuelCost.setLedgerVat(this.ledgerVatStore);
            })
        }
    }

    afterChangeFc = () => {
        const { fuelCost } = this.props;

        if (fuelCost.isOwnCurrency) {
            fuelCost.syncOc();
        }

        fuelCost.recalculateVat();
        fuelCost.calculateUnitPrice();  // T25743
    }

    afterChangeGross = () => {
        const { fuelCost, disableNet } = this.props;

        if (disableNet) {
            fuelCost.syncNet();
        }

        fuelCost.recalculateVat();
    }

    afterChangeCostSupplier = () => {
        const { fuelCost } = this.props;

        fuelCost.ledgerCost.clear();

        if(!fuelCost.id){
            fuelCost.costProduct.clear();
        }
        this.costProductStore.clear();
        this.costProductStore.params['.cost_product_supplier_codes.cost_supplier'] = fuelCost.costSupplier.id;
        this.costProductStore.fetch();
    }

    renderForm() {
        const { fuelCost, showEntity, showSupplier, disableNet, showHaveReceipt } = this.props;

        const optionsForEntity = this.costProductStore
        .filter(c =>
            c.entities.length === 0 || // Include if no entities
                c.entities.map(e => e.id).includes(fuelCost.driver?.entity?.id) // Or if the entity ID matches
        )
        .map(costProduct => ({
            value: costProduct.id,
            text: costProduct.description
        }));


        return (
            <Form>
                {/* <Form.Group widths="equal"> */}
                    {/* <TargetSelect remote fluid */}
                    {/*     target={fuelCost} */}
                    {/*     name="costSupplier" */}
                    {/*     store={this.costSupplierStore} */}
                    {/*     toOption={costSupplier => ({ */}
                    {/*         text: costSupplier.name, */}
                    {/*         value: costSupplier.id, */}
                    {/*     })} */}
                    {/* /> */}
                    {/* <TargetSelect remote fluid */}
                    {/*     target={fuelCost} */}
                    {/*     name="ledgerCost" */}
                    {/*     store={this.ledgerCostStore} */}
                    {/*     toOption={ledgerCost => ({ */}
                    {/*         text: `${ledgerCost.ledgerCodeAccounting} - ${ledgerCost.ledgerCodeReporting} - ${ledgerCost.assetTruckCategory}`, */}
                    {/*         value: ledgerCost.id, */}
                    {/*     })} */}
                    {/* /> */}
                {/* </Form.Group> */}
                {/* <Form.Group widths="equal"> */}
                    {/* <TargetSelect remote fluid */}
                    {/*     target={fuelCost} */}
                    {/*     name="ledgerVat" */}
                    {/*     store={this.ledgerVatStore} */}
                    {/*     toOption={ledgerVat => ({ */}
                    {/*         text: `${ledgerVat.ledgerCode} - ${ledgerVat.country}`, */}
                    {/*         value: ledgerVat.id, */}
                    {/*     })} */}
                    {/* /> */}
                    {/* <TargetNumberInput fluid */}
                    {/*     target={fuelCost} */}
                    {/*     name="transactionId" */}
                    {/* /> */}
                    {/* <TargetDatePicker fluid required */}
                    {/*     target={fuelCost} */}
                    {/*     name="transactionDatetime" */}
                    {/*     autoComplete="off" */}
                    {/* /> */}
                {/* </Form.Group> */}
                <Form.Group widths="equal">
                    {showEntity && (
                        <TargetSelect remote fluid
                            target={fuelCost}
                            name="entity"
                            store={this.entityStore}
                            toOption={entity => ({
                                text: entity.name,
                                value: entity.id,
                            })}
                        />
                    )}
                    {showSupplier && (
                        <TargetSelect remote fluid
                            target={fuelCost}
                            name="costSupplier"
                            store={this.costSupplierStore}
                            toOption={costSupplier => ({
                                text: costSupplier.name,
                                value: costSupplier.id,
                            })}
                            afterChange={() => {
                                this.findLedgerVat();
                            }}
                        />
                    )}
                    <TargetSelect remote fluid
                        target={fuelCost}
                        name="costProduct"
                        store={this.costProductStore}
                        options={optionsForEntity}
                        // disabled={fuelCost.costSupplier.isNew}
                        afterChange={() => {
                            this.findLedgerCost();
                            if (this.props.withDeclaration) {
                                this.props.declaration.setInput('costProduct', fuelCost.costProduct);
                            }
                        }}
                    />
                    {/* <TargetTextInput fluid */}
                    {/*     target={fuelCost} */}
                    {/*     name="originalProductDescription" */}
                    {/* /> */}
                    {/* <TargetTextInput fluid */}
                    {/*     target={fuelCost} */}
                    {/*     name="originalCardNumber" */}
                    {/* /> */}
                    <TargetCurrencySelect fluid
                        target={fuelCost}
                        name="fcCode"
                    />
                    <TargetNumberInput fluid allowDecimal
                        target={fuelCost}
                        name="quantity"
                        afterChange={() => {
                            fuelCost.calculateUnitPrice();  // T25743
                        }}
                    />
                    {(showHaveReceipt || this.props.withDeclaration) && (
                        <TargetRadioButtons required data-test-road-cost-have-receipt
                            target={fuelCost}
                            name="haveReceipt"
                            options={[
                                { value: false, text: t('form.no') },
                                { value: true, text: t('form.yes') },
                            ]}
                            afterChange={() => {
                                this.findLedgerCost();
                            }}
                        />
                    )}
                    {(!showHaveReceipt || this.props.withDeclaration) && (
                        <TargetRadioButtons required data-test-road-cost-paid-by
                            target={fuelCost}
                            name="paidBy"
                            options={[
                                { value: 'company', text: t('costSupplier.field.paidBy.company') },
                                { value: 'driver', text: t('costSupplier.field.paidBy.driver') },
                            ]}
                        />
                    )}
                </Form.Group>
                <Form.Group widths="equal">
                    <TargetSelect remote fluid required
                        target={fuelCost}
                        name="truck"
                        store={this.truckStore}
                        toOption={truck => ({
                            value: truck.id,
                            text: truck.licensePlate
                        })}
                        afterChange={() => {
                            fuelCost.entity = fuelCost.truck.entity;
                            this.findLocation();
                            this.findLedgerCost();
                        }}
                    />
                    {/* <TargetSelect remote fluid */}
                    {/*     target={fuelCost} */}
                    {/*     name="trailer" */}
                    {/*     store={this.trailerStore} */}
                    {/*     toOption={trailer => ({ */}
                    {/*         value: trailer.id, */}
                    {/*         text: trailer.licensePlate */}
                    {/*     })} */}
                    {/* /> */}
                    <TargetSelect remote fluid
                        target={fuelCost}
                        name="driver"
                        store={this.driverStore}
                        toOption={driver => ({
                            value: driver.id,
                            text: driver.name
                        })}
                        disabled={this.props.withDeclaration}
                    />
                    <DatetimePicker fluid required data-test-provide-date
                        model={fuelCost}
                        name="transactionDatetime"
                        afterChange={this.findLocation}
                    />
                    <TargetRadioButtons required
                        target={fuelCost}
                        name="productFor"
                        options={[
                            { value: 'truck', text: t('fuelCost.field.productFor.truck') },
                            { value: 'trailer', text: t('fuelCost.field.productFor.trailer') },
                        ]}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field width={3}>
                        <FormLabel required>{t('incident.field.activity.label')}</FormLabel>
                        {fuelCost.activity.isNew
                            ? (
                                <React.Fragment>
                                    {/* {copy-paste-empty-activity} */}
                                    <StyledContainer data-test-empty-activity warning>
                                        <IconSide>
                                            ?
                                        </IconSide>
                                        <ContentSide>
                                            <Loader disabled={!this.fetchingActivity} inline="centered" />
                                        </ContentSide>
                                    </StyledContainer>
                                    {fuelCost.__backendValidationErrors.activity && fuelCost.__backendValidationErrors.activity.length > 0 && (
                                        <ErrorLabel>
                                            {fuelCost.__backendValidationErrors.activity.map(error => (
                                                <div>{error}</div>
                                            ))}
                                        </ErrorLabel>
                                    )}
                                </React.Fragment>
                            ) : (
                                <ModalActivityFinalize
                                    activityId={fuelCost.activity.id}
                                    viewStore={getViewStore()}
                                    trigger={props => (
                                        <Activity
                                            activity={fuelCost.activity}
                                            backgroundColor={BACKGROUND_COLOR}
                                            {...props}
                                        />
                                    )}
                                />
                            )
                        }
                    </Form.Field>
                    <Form.Field width={13}>
                        <LocationEditFreeForm
                            onChange={(location) => {
                                // Prevent the old location from being used if the location is cleared
                                if (location.address === '') {
                                    location = null;
                                }
                                // Manually input address
                                fuelCost.setInput('location', location);
                            }}
                            changeOnEmpty={true}
                            label={t('activity.field.location.label')}
                            location={fuelCost.location}
                            error={fuelCost.backendValidationErrors.location}
                        />
                    </Form.Field>
                    {/* <TargetTextInput fluid */}
                    {/*     target={fuelCost} */}
                    {/*     name="originalAddress" */}
                    {/* /> */}
                    {/* <TargetTextInput fluid */}
                    {/*     target={fuelCost} */}
                    {/*     name="originalCountryCode" */}
                    {/* /> */}
                    {/* <TargetNumberInput fluid */}
                    {/*     target={fuelCost} */}
                    {/*     name="odometer" */}
                    {/* /> */}
                </Form.Group>
                <Form.Group widths="equal">
                    {fuelCost.costInvoice.id && (
                        <TargetNumberInput fluid allowDecimal
                            target={fuelCost}
                            name="unitPrice"
                            decimalLimit={4}
                        />
                    )}
                    {fuelCost.costInvoice.id && (
                        <TargetNumberInput fluid allowDecimal
                            target={fuelCost}
                            name="listPrice"
                            decimalLimit={4}
                        />
                    )}
                    <TargetNumberInput allowDecimal fluid disabled
                        target={fuelCost}
                        name="vatPercentage"
                        decimalLimit={2}
                        suffix='%'
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <TargetMoneyInput fluid allowNegative
                        target={fuelCost}
                        name="netFc"
                        disabled={disableNet}
                        afterChange={() => {
                            this.afterChangeFc();
                        }}
                    />
                    <TargetMoneyInput fluid allowNegative
                        target={fuelCost}
                        name="grossFc"
                        afterChange={() => {
                            this.afterChangeFc();
                            this.afterChangeGross();
                        }}
                    />
                    <TargetMoneyInput fluid disabled allowNegative
                        target={fuelCost}
                        name="vatFc"
                    />
                    <TargetSelect fluid disabled remote search
                        target={fuelCost}
                        name="ledgerVat"
                        store={this.ledgerVatStore}
                        toOption={lv => ({
                            value: lv.id,
                            text: lv.getLedgerVatText(),
                        })}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <TargetMoneyInput fluid allowNegative
                        target={fuelCost}
                        name="netOc"
                        disabled={fuelCost.isOwnCurrency || disableNet}
                    />
                    <TargetMoneyInput fluid allowNegative
                        target={fuelCost}
                        name="grossOc"
                        disabled={fuelCost.isOwnCurrency}
                        afterChange={() => {
                            this.afterChangeGross();
                            fuelCost.recalculateVat();
                        }}
                    />
                    <TargetMoneyInput fluid disabled allowNegative
                        target={fuelCost}
                        name="vatOc"
                    />
                    <TargetSelect fluid disabled remote search
                        target={fuelCost}
                        name="ledgerCost"
                        store={this.ledgerCostStore}
                        toOption={lc => ({
                            value: lc.id,
                            text: lc.getLedgerCostText(fuelCost.haveReceipt, fuelCost.transactionDatetime),
                        })}
                    />
                </Form.Group>
                {(fuelCost.declaration && fuelCost.id && !this.props.disableDeclarationForCostInvoice) && <DeclarationSeparatedDraw isRoadCost={false} declaration={fuelCost.declaration}/>}
            </Form>
        );
    }

    renderFormForDeclaration() {
        const { fuelCost, showEntity, showSupplier, disableNet, showHaveReceipt } = this.props;

        const optionsForEntity = this.costProductStore
        .filter(c =>
            c.entities.length === 0 || // Include if no entities
                c.entities.map(e => e.id).includes(fuelCost.driver?.entity?.id) // Or if the entity ID matches
        )
        .map(costProduct => ({
            value: costProduct.id,
            text: costProduct.description
        }));


        return (
            <Form>
                <Form.Group widths="equal">
                    {showEntity && (
                        <TargetSelect remote fluid
                            target={fuelCost}
                            name="entity"
                            store={this.entityStore}
                            toOption={entity => ({
                                text: entity.name,
                                value: entity.id,
                            })}
                        />
                    )}
                    {showSupplier && (
                        <TargetSelect remote fluid
                            target={fuelCost}
                            name="costSupplier"
                            store={this.costSupplierStore}
                            toOption={costSupplier => ({
                                text: costSupplier.name,
                                value: costSupplier.id,
                            })}
                            afterChange={() => {
                                this.findLedgerVat();
                            }}
                        />
                    )}
                    <TargetSelect remote fluid
                        target={fuelCost}
                        name="costProduct"
                        store={this.costProductStore}
                        options={optionsForEntity}
                        afterChange={() => {
                            this.findLedgerCost();
                            if (this.props.withDeclaration) {
                                this.props.declaration.setInput('costProduct', fuelCost.costProduct);
                            }
                        }}
                    />
                    <TargetCurrencySelect fluid
                        target={fuelCost}
                        name="fcCode"
                    />
                    <TargetNumberInput fluid allowDecimal
                        target={fuelCost}
                        name="quantity"
                        afterChange={() => {
                            fuelCost.calculateUnitPrice();
                        }}
                    />
                    {(showHaveReceipt || this.props.withDeclaration) && (
                        <TargetRadioButtons required data-test-road-cost-have-receipt
                            target={fuelCost}
                            name="haveReceipt"
                            options={[
                                { value: false, text: t('form.no') },
                                { value: true, text: t('form.yes') },
                            ]}
                            afterChange={() => {
                                this.findLedgerCost();
                            }}
                        />
                    )}
                    {(!showHaveReceipt || this.props.withDeclaration) && (
                        <TargetRadioButtons required data-test-road-cost-paid-by
                            target={fuelCost}
                            name="paidBy"
                            options={[
                                { value: 'company', text: t('costSupplier.field.paidBy.company') },
                                { value: 'driver', text: t('costSupplier.field.paidBy.driver') },
                            ]}
                            // disabled={(!currentUser.hasPermission(['cost.manage_declaration:cash']) || this.props.declaration.creator === 'simpled' || this.props.declaration.creator === 'driver') && this.props.declaration.id}
                        />
                    )}
                </Form.Group>
                <Form.Group widths="equal">
                    <TargetSelect remote fluid required
                        target={fuelCost}
                        name="truck"
                        store={this.truckStore}
                        toOption={truck => ({
                            value: truck.id,
                            text: truck.licensePlate
                        })}
                        afterChange={() => {
                            fuelCost.entity = fuelCost.truck.entity;
                            this.findLocation();
                            this.findLedgerCost();
                        }}
                    />
                    <TargetTextInput remote fluid
                        target={fuelCost.driver}
                        name="dataExternalId"
                        disabled={this.props.withDeclaration}
                    />
                    <TargetSelect remote fluid
                        target={fuelCost}
                        name="driver"
                        store={this.driverStore}
                        toOption={driver => ({
                            value: driver.id,
                            text: driver.name
                        })}
                        disabled={this.props.withDeclaration}
                    />
                    <DatetimePicker fluid required data-test-provide-date
                        model={fuelCost}
                        name="transactionDatetime"
                        afterChange={this.findLocation}
                    />
                    <TargetRadioButtons required
                        target={fuelCost}
                        name="productFor"
                        options={[
                            { value: 'truck', text: t('fuelCost.field.productFor.truck') },
                            { value: 'trailer', text: t('fuelCost.field.productFor.trailer') },
                        ]}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field width={3}>
                        <FormLabel required>{t('incident.field.activity.label')}</FormLabel>
                        {fuelCost.activity.isNew
                            ? (
                                <React.Fragment>
                                    <StyledContainer data-test-empty-activity warning>
                                        <IconSide>
                                            ?
                                        </IconSide>
                                        <ContentSide>
                                            <Loader disabled={!this.fetchingActivity} inline="centered" />
                                        </ContentSide>
                                    </StyledContainer>
                                    {fuelCost.__backendValidationErrors.activity && fuelCost.__backendValidationErrors.activity.length > 0 && (
                                        <ErrorLabel>
                                            {fuelCost.__backendValidationErrors.activity.map(error => (
                                                <div>{error}</div>
                                            ))}
                                        </ErrorLabel>
                                    )}
                                </React.Fragment>
                            ) : (
                                <ModalActivityFinalize
                                    activityId={fuelCost.activity.id}
                                    viewStore={getViewStore()}
                                    trigger={props => (
                                        <Activity
                                            activity={fuelCost.activity}
                                            backgroundColor={BACKGROUND_COLOR}
                                            {...props}
                                        />
                                    )}
                                />
                            )
                        }
                    </Form.Field>
                    <Form.Field width={13}>
                        <LocationEditFreeForm
                            onChange={(location) => {
                                if (location.address === '') {
                                    location = null;
                                }
                                // Manually input address
                                fuelCost.setInput('location', location);
                            }}
                            changeOnEmpty={true}
                            label={t('activity.field.location.label')}
                            location={fuelCost.location}
                            error={fuelCost.backendValidationErrors.location}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    {fuelCost.costInvoice.id && (
                        <TargetNumberInput fluid allowDecimal
                            target={fuelCost}
                            name="unitPrice"
                            decimalLimit={4}
                        />
                    )}
                    {fuelCost.costInvoice.id && (
                        <TargetNumberInput fluid allowDecimal
                            target={fuelCost}
                            name="listPrice"
                            decimalLimit={4}
                        />
                    )}
                    <TargetNumberInput allowDecimal fluid disabled
                        target={fuelCost}
                        name="vatPercentage"
                        decimalLimit={2}
                        suffix='%'
                    />
                     <TargetMoneyInput fluid allowNegative
                        target={fuelCost}
                        name="netFc"
                        disabled={disableNet}
                        afterChange={() => {
                            this.afterChangeFc();
                        }}
                    />
                    <TargetMoneyInput fluid allowNegative
                        target={fuelCost}
                        name="grossFc"
                        afterChange={() => {
                            this.afterChangeFc();
                            this.afterChangeGross();
                            fuelCost.recalculateValuesForDeclaration()
                            fuelCost.recalculateValuesForDeclarationEurOc()
                            fuelCost.recalculateValuesForDeclarationEurFc()
                        }}
                    />
                    <TargetMoneyInput fluid disabled allowNegative
                        target={fuelCost}
                        name="vatFc"
                    />
                    <TargetMoneyInput fluid allowNegative
                        target={fuelCost}
                        name="netOc"
                        disabled={fuelCost.isOwnCurrency || disableNet}
                    />
                    <TargetMoneyInput fluid allowNegative
                        target={fuelCost}
                        name="grossOc"
                        disabled={fuelCost.isOwnCurrency}
                        afterChange={() => {
                            this.afterChangeGross();
                            fuelCost.recalculateVat();
                            fuelCost.recalculateValuesForDeclaration()
                            fuelCost.recalculateValuesForDeclarationEurOc()
                            fuelCost.recalculateValuesForDeclarationEurFc()
                        }}
                    />
                    <TargetMoneyInput fluid disabled allowNegative
                        target={fuelCost}
                        name="vatOc"
                    />
                    <TargetSelect fluid disabled remote search
                        target={fuelCost}
                        name="ledgerVat"
                        store={this.ledgerVatStore}
                        toOption={lv => ({
                            value: lv.id,
                            text: lv.getLedgerVatText(),
                        })}
                    />
                    <TargetSelect fluid disabled remote search
                        target={fuelCost}
                        name="ledgerCost"
                        store={this.ledgerCostStore}
                        toOption={lc => ({
                            value: lc.id,
                            text: lc.getLedgerCostText(fuelCost.haveReceipt, fuelCost.transactionDatetime),
                        })}
                    />
                </Form.Group>
                {(fuelCost.declaration && fuelCost.id && !this.props.disableDeclarationForCostInvoice) && <DeclarationSeparatedDraw isRoadCost={false} declaration={fuelCost.declaration}/>}
            </Form>
        );
    }

    render() {
        const { fuelCost } = this.props;

        return (
            <Body>
                <ContentContainer>
                    <Content>
                        <Header as="h1">
                            {fuelCost.id
                                ? t('fuelCost.edit.title')
                                : t('fuelCost.create.title')}
                                {fuelCost.costInvoice.id && (
                                    <Link to={`/administration/cost-invoice/${fuelCost.costInvoice.id}/edit` } icon >
                                        <IconButton title="View related Cost invoice" name='eye'/>
                                    </Link>
                                )}
                        </Header>
                        {this.renderForm()}
                    </Content>
                </ContentContainer>
                <Toolbar>
                    <RightDivider />
                    {!fuelCost.rejectedAt ? <SaveButton compact data-test-save-and-finalize-button
                        color={'red'}
                        content={t('costInvoice.edit.rejectButton')}
                        onClick={()=>fuelCost.rejectFuelCost()}
                        loading={fuelCost.isLoading}
                        disabled={!fuelCost.ledgerCost.id || (fuelCost.location && !fuelCost.location.id) || !fuelCost.grossOc}
                    /> :
                    <SaveButton compact data-test-save-and-finalize-button
                        color={'red'}
                        content={t('costInvoice.edit.unrejectButton')}
                        onClick={()=>fuelCost.unrejectFuelCost()}
                        loading={fuelCost.isLoading}
                        disabled={!fuelCost.ledgerCost.id || (fuelCost.location && !fuelCost.location.id) || !fuelCost.grossOc}
                    />
                    }
                    {!!fuelCost.finalizedAt && (
                        <SaveButton compact data-test-unfinalize-button
                            color="purple"
                            content={t('form.unfinalizeButton')}
                            onClick={()=>fuelCost.unfinalize()}
                            loading={fuelCost.isLoading}
                            disabled={fuelCost.rejectedAt}
                        />
                    )}
                    <SaveButton primary compact data-test-save-and-finalize-button
                        content={t('costInvoice.edit.saveAndFinalizeButton')}
                        onClick={() => this.saveAndFinalize(fuelCost)}
                        loading={fuelCost.isLoading}
                        disabled={!fuelCost.ledgerCost.id || (fuelCost.location && !fuelCost.location.id) || fuelCost.rejectedAt || !fuelCost.grossOc}
                    />

                    <Popup hoverable
                        content={!fuelCost.ledgerCost.id? t('fuelCost.create.ledgerCostWarning'):((fuelCost.location && !fuelCost.location.id) && t('fuelCost.create.locationWarning'))}
                        trigger={
                            <span data-test-save-button-container>
                                <SaveButton primary compact
                                    onClick={fuelCost.isNew ? (() => this.saveNew(fuelCost)) : this.save}
                                    loading={fuelCost.isLoading}
                                    content={fuelCost.id?t('fuelCost.create.saveButton'):t('fuelCost.create.saveAndNewButton')}
                                    disabled={!fuelCost.ledgerCost.id || (fuelCost.location && !fuelCost.location.id) || fuelCost.rejectedAt || !fuelCost.grossOc}
                                />
                            </span>}
                    />
                </Toolbar>
            </Body>
        );
    }
}

@observer
export class EditModal extends FuelCostEdit {
    static propTypes = {
        fuelCost: PropTypes.instanceOf(FuelCost).isRequired,
        afterSave: PropTypes.func.isRequired,
        trigger: PropTypes.func.isRequired,
        disableDeclarationForCostInvoice: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        ...FuelCostEdit.defaultProps,
        showEntity: false,
        showSupplier: false,
        disableNet: false,
        getLedgerVat: true,
        showHaveReceipt: false,
        trigger(props) {
            return <EditButton {...props} />;
        },
        disableDeclarationForCostInvoice: true,
    }

    constructor(...args) {
        super(...args);
        this.save = this.save.bind(this);
    }

    @observable isOpen = false;
    open = () => this.isOpen = true;
    close = () => this.isOpen = false;

    save() {
        const { afterSave } = this.props;

        super.save()
            .then(afterSave)
            .then(this.close);
    }

    saveAndFinalize() {
        const { afterSave, fuelCost } = this.props;

        super.save()
            .then(()=>fuelCost.finalize())
            .then(afterSave)
            .then(this.close);
    }

    render() {
        const { fuelCost, trigger, ...props } = this.props;

        return (
            <Modal {...props}
                centered={false}
                open={this.isOpen}
                onClose={this.close}
                trigger={trigger({ onClick: this.open })}
            >
                <Modal.Content>
                    {this.renderForm()}
                </Modal.Content>
                <Modal.Actions>
                    <CancelButton onClick={this.close} />
                    <RightDivider />
                    <Popup hoverable
                        content={!fuelCost.ledgerCost.id? t('fuelCost.create.ledgerCostWarning'):((fuelCost.location && !fuelCost.location.id) && t('fuelCost.create.locationWarning'))}
                        trigger={
                            <span data-test-save-button>
                                <SaveButton primary compact
                                    onClick={this.save}
                                    loading={fuelCost.isLoading}
                                    disabled={!fuelCost.ledgerCost.id || (fuelCost.location && !fuelCost.location.id) || fuelCost.rejectedAt}
                                />
                            </span>}
                    />
                    <SaveButton primary compact data-test-save-and-finalize-button
                        content={t('costInvoice.edit.saveAndFinalizeButton')}
                        onClick={()=>this.saveAndFinalize()}
                        loading={fuelCost.isLoading}
                        disabled={!fuelCost.ledgerCost.id || (fuelCost.location && !fuelCost.location.id) || fuelCost.rejectedAt}
                    />
                     {!!fuelCost.finalizedAt && (
                        <SaveButton compact data-test-unfinalize-button
                            color="purple"
                            content={t('form.unfinalizeButton')}
                            onClick={()=>fuelCost.unfinalize()}
                            loading={fuelCost.isLoading}
                        />
                    )}

                </Modal.Actions>
            </Modal>
        );
    }
}

@observer
export class FuelCostForDeclaration extends FuelCostEdit {
    static propTypes = {
        fuelCost: PropTypes.instanceOf(FuelCost).isRequired,
        afterSave: PropTypes.func.isRequired,
        trigger: PropTypes.func.isRequired,
    };

    static defaultProps = {
        ...FuelCostEdit.defaultProps,
        showEntity: false,
        showSupplier: false,
        disableNet: false,
        getLedgerVat: true,
        showHaveReceipt: false,
        withDeclaration: true,
        trigger(props) {
            return <EditButton {...props} />;
        }
    }

    constructor(...args) {
        super(...args);
        this.save = this.save.bind(this);
    }

    @observable isOpen = false;
    open = () => this.isOpen = true;
    close = () => this.isOpen = false;

    save() {
        const { afterSave } = this.props;

        super.save()
            .then(afterSave)
            .then(this.close);
    }

    saveAndFinalize() {
        const { afterSave, fuelCost } = this.props;

        super.save()
            .then(()=>fuelCost.finalize())
            .then(afterSave)
            .then(this.close);
    }

    render() {

        return (
            <div>{this.renderFormForDeclaration()}</div>
        );
    }
}
