import { observable, computed } from 'mobx';
import { Model, Store, Casts, FileField } from 'store/Base';
import { Supplier } from './Supplier';
import { Entity } from './Entity';
import { Truck } from './Truck';
import { DATE_SHORT_FORMAT } from 'helpers';
import { padStart } from 'lodash';

export const TYPE_OPERATIONAL = 'operational';
export const TYPE_FINANCIAL = 'financial';
export const TYPE_RENT = 'rent';

export class LeaseContract extends Model {
    static backendResourceName = 'lease_contract';
    static terminationFrequencies = ['daily', 'weekly', 'monthly', 'quarterly', 'end']

    @observable id = null;
    @observable startDate = null;
    @observable endDate = null;
    @observable contractNumber = '';
    @observable downPayment = 0;
    @observable monthlyPayment = 0;
    @observable finalPayment = 0;
    @observable roadTaxPayment = 0;
    @observable type = TYPE_OPERATIONAL;
    @observable includingServiceContract = false;
    @observable includingInsurance = false;
    @observable includingRoadTax = false;
    @observable remarks = '';
    @observable contractScan = new FileField({ name: 'contractScan' });
    @observable isTvmIntegration = false;

    @observable terminationFrequency = null;
    @observable terminationDate = null;

    @computed get label() {
        if (this.startDate && this.endDate && this.leaseCompany.name) {
            return this.startDate.format(DATE_SHORT_FORMAT) + ' - ' +
                this.leaseCompany.name + ' - ' +
                this.endDate.format(DATE_SHORT_FORMAT);
        }

        return '';
    }

    relations() {
        return {
            truck: Truck,
            leaseCompany: Supplier,
            entity: Entity,
        };
    }

    casts() {
        return {
            startDate: Casts.date,
            endDate: Casts.date,
            contractScan: Casts.file,
            terminationDate: Casts.date,
        };
    }

    saveAsCopy(leaseContract) {
        return this.save({ url: leaseContract.url + 'copy/' });
    }

    // Take the monthly amount.  If the contract starts or ends on the
    // given month, calculate the ratio of the contract that needs to
    // be paid (e.g., on the 15th, it's half the monthly amount).
    getAmountForMonth(year, month) {
        const fmtYearMonth = padStart(year, 4, '0') + '-' + padStart(month, 2, '0');
        if (fmtYearMonth <= this.startDate.format('YYYY-MM')) {
            if (year < this.startDate.year()) {
                return 0;
            } else {
                const remainingDays = this.startDate.daysInMonth() - this.startDate.date();
                return Math.round(this.monthlyPayment / this.startDate.daysInMonth() * remainingDays);
            }
        } else if (fmtYearMonth >= this.endDate.format('YYYY-MM')) {
            if (year > this.endDate.year()) {
                return 0;
            } else {
                return Math.round(this.monthlyPayment / this.endDate.daysInMonth() * this.endDate.date());
            }
        } else {
            return this.monthlyPayment;
        }
    }
}

export class LeaseContractStore extends Store {
    Model = LeaseContract;
    static backendResourceName = 'lease_contract';
}
