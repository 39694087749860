import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { CostProductSupplierCodeStore } from 'store/CostProductSupplierCode';
import { EntityStore } from './Entity';

export class CostProduct extends Model {
    static backendResourceName = 'cost_product';

    @observable id = null;
    @observable description = '';
    @observable costType = 'fuel';
    @observable assignActivity = true;
    @observable mobileIconName = ''

    relations() {
        return {
            costProductSupplierCodes: CostProductSupplierCodeStore,
            entities: EntityStore,
        }
    }
}

export class CostProductStore extends Store {
    static backendResourceName = 'cost_product';

    Model = CostProduct;
}
