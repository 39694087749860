import moment from 'moment';
import { observable, action } from 'mobx';
import { capitalize } from 'lodash';
import { hasPermission } from 'helpers/permission';
import { Model, Store, Casts } from 'store/Base';
import { Entity } from './Entity';
import { Driver } from './Driver';
import { Customer } from './Customer';
import { Activity } from './Activity';
import { Supplier } from './Supplier';
import { User } from './User';
import { Truck } from './Truck';
import { Trailer } from './Trailer';
import { Invoice } from './Invoice';
import { BEPFollowupStore, BEPFollowupActionCategory } from './BEPFollowup';
import { BEPDocumentStore } from './BEPDocument';
import { Incident } from './Incident';
import { DriverBonus } from './DriverBonus';
import { BonusStore } from './Bonus';

export const SUBJECT_TYPE_DRIVER = 'driver';
export const SUBJECT_TYPE_CUSTOMER = 'customer';
export const SUBJECT_TYPE_USER = 'user';
export const SUBJECT_TYPE_ENTITY = 'entity';
export const SUBJECT_TYPE_SUPPLIER = 'supplier';
export const SUBJECT_TYPE_SUBCATEGORY = 'subcategory';
export const SUBJECT_TYPE = [SUBJECT_TYPE_DRIVER, SUBJECT_TYPE_CUSTOMER, SUBJECT_TYPE_USER, SUBJECT_TYPE_ENTITY, SUBJECT_TYPE_SUPPLIER, SUBJECT_TYPE_SUBCATEGORY];
export const SUBJECT_FROM_TYPE = [SUBJECT_TYPE_DRIVER, SUBJECT_TYPE_CUSTOMER, SUBJECT_TYPE_USER, SUBJECT_TYPE_SUPPLIER];
export const SUBJECT_TO_TYPE = [SUBJECT_TYPE_DRIVER, SUBJECT_TYPE_CUSTOMER, SUBJECT_TYPE_USER, SUBJECT_TYPE_ENTITY, SUBJECT_TYPE_SUPPLIER, SUBJECT_TYPE_SUBCATEGORY];

export const MEANING_IDEA = 'idea';
export const MEANING_POSITIVE = 'positive';
export const MEANING_NEGATIVE = 'negative';
export const MEANING = [MEANING_IDEA, MEANING_POSITIVE, MEANING_NEGATIVE];

export const PRIORITY_LOW = '3-low';
export const PRIORITY_NORMAL = '2-normal';
export const PRIORITY_HIGH = '1-high';
export const PRIORITIES = [PRIORITY_LOW, PRIORITY_NORMAL, PRIORITY_HIGH];

export const IMPACT_SMALL = 'small';
export const IMPACT_LARGE = 'large';

export const CONTEXT_TYPE_ACTIVITY = 'activity';
export const CONTEXT_TYPE_INVOICE = 'invoice';
export const CONTEXT_TYPE_TRUCK = 'truck';
export const CONTEXT_TYPE_TRAILER = 'trailer';
export const CONTEXT_TYPE_INCIDENT = 'incident';

export const QUALITY_SYSTEM_ISO = 'iso';
export const QUALITY_SYSTEM_AEO = 'aeo';
export const QUALITY_SYSTEM_TAPA = 'tapa';
export const QUALITY_SYSTEM_GDP = 'gdp';
export const QUALITY = [QUALITY_SYSTEM_ISO, QUALITY_SYSTEM_AEO, QUALITY_SYSTEM_TAPA, QUALITY_SYSTEM_GDP];

export const SYSTEM_FUNCTION_DRIVER_TICKET_ON_CUSTOMER_DOCUMENT_REJECTION = 'driver ticket on customer document rejection';
export const SYSTEM_FUNCTION_DISPATCHER_TICKET_ON_CUSTOMER_DOCUMENT_REJECTION = 'dispatcher ticket on customer document rejection';
export const SYSTEM_FUNCTION = [
    SYSTEM_FUNCTION_DRIVER_TICKET_ON_CUSTOMER_DOCUMENT_REJECTION,
    SYSTEM_FUNCTION_DISPATCHER_TICKET_ON_CUSTOMER_DOCUMENT_REJECTION
];

export class BEPTicketSubcategory extends Model {
    static backendResourceName = 'bepticket_subcategory';

    @observable id = null;
    @observable slug = '';
    @observable name = '';
    @observable isSystem = false;
    @observable deleted = false;
}

export class BEPTicketSubcategoryStore extends Store {
    Model = BEPTicketSubcategory;
    static backendResourceName = 'bepticket_subcategory';
}

export class BEPTicketCategory extends Model {
    static backendResourceName = 'bepticket_category';

    @observable id = null;
    @observable name = '';

    //for some ID if firstDriverCareManager is true, the followup action taker will be always first driver care manager of driver
    @observable firstDriverCareManager = false;
    @observable relatedToSalary = false;
    @observable autoFinalizeSalary = false;
    @observable bepAmount = null;
    @observable numberOfRepetitions = null;
    @observable fixed = false;
    @observable qualitySystems = [];
    @observable needsFollowup = true;
    @observable followupActionDays = null;
    @observable meanings = [];
    @observable subjectToTypes = SUBJECT_TYPE; // Default to all
    @observable systemFunctions = [];

    @observable createdAt = null;
    @observable updatedAt = null;
    @observable deleted = false;

    acceptableForTicket(bepTicket) {
        if (bepTicket.subjectToType === SUBJECT_TYPE_SUBCATEGORY && !this.subjectToSubcategories.get(bepTicket.subjectToSubcategory.id))
            return false;

        return this.meanings.includes(bepTicket.meaning) && this.subjectToTypes.includes(bepTicket.subjectToType);
    }

    populateTicketFromDefaults(bepTicket) {
        bepTicket.setInput('needsFollowup', this.needsFollowup);

        if (this.followupActionTaker.id) {
            bepTicket.setInput('followupActionTaker', this.followupActionTaker);
        }

        if (this.followupActionCategory.id) {
            bepTicket.setInput('followupActionCategory', this.followupActionCategory);
        }

        if (this.followupActionDays !== null) {
            bepTicket.setInput('followupActionDate', moment().add(this.followupActionDays, 'days'));
        } else {
            bepTicket.setInput('followupActionDate', null);
        }

        this.allowedSubjectToTypes = this.subjectToTypes;

        bepTicket.setInput('qualitySystems', this.qualitySystems);
    }

    relations() {
        return {
            followupActionTaker: User,
            followupActionCategory: BEPFollowupActionCategory,
            subjectToSubcategories: BEPTicketSubcategoryStore,
            driverBonus: DriverBonus,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
};

export class BEPTicketCategoryStore extends Store {
    Model = BEPTicketCategory;
    static backendResourceName = 'bepticket_category';
};


export class BEPTicket extends Model {
    static backendResourceName = 'bepticket';

    @observable id = null;
    @observable meaning = MEANING_IDEA;
    @observable impact = IMPACT_SMALL;
    @observable priority = PRIORITY_NORMAL;
    @observable description = '';

    @observable subjectFromType = SUBJECT_TYPE_USER;
    @observable subjectToType = SUBJECT_TYPE_USER;
    @observable contextType = null;

    @observable qualitySystems = [];
    @observable needsFollowup = true;
    @observable followupActionDate = null;

    @observable finishedAt = null;

    @observable review = '';
    @observable reviewedAt = null;

    @observable createdAt = null;
    @observable updatedAt = null;

    @observable firstDriverCareManager = false;

    relations() {
        return {
            category: BEPTicketCategory,
            entity: Entity,

            subjectFromDriver: Driver,
            subjectFromCustomer: Customer,
            subjectFromUser: User,
            subjectFromSupplier: Supplier,

            subjectToDriver: Driver,
            subjectToCustomer: Customer,
            subjectToUser: User,
            subjectToEntity: Entity,
            subjectToSupplier: Supplier,
            subjectToSubcategory: BEPTicketSubcategory,

            contextActivity: Activity,
            contextInvoice: Invoice,
            contextTruck: Truck,
            contextTrailer: Trailer,
            contextIncident: Incident,

            followupActionCategory: BEPFollowupActionCategory,
            followupActionTaker: User,
            finishedBy: User,
            reviewedBy: User,
            createdBy: User,

            followups: BEPFollowupStore,
            documents: BEPDocumentStore,
            bonuses: BonusStore,
        };
    }

    casts() {
        return {
            followupActionDate: Casts.date,
            finishedAt: Casts.datetime,
            reviewedAt: Casts.datetime,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    subjectFromText() {
        const type = this.subjectFromType;
        const attr = 'subjectFrom'+capitalize(type);
        if (this[attr] !== null) {
            switch(type) {
            case SUBJECT_TYPE_DRIVER:
            case SUBJECT_TYPE_CUSTOMER:
            case SUBJECT_TYPE_SUPPLIER:
                return this[attr].name;
            case SUBJECT_TYPE_USER:
                return this[attr].fullName;
            default:
                throw new Error('Unknown subject from type: ' + type);
            }
        } else {
            return ''; // Nothing selected yet
        }
    }

    subjectToText() {
        const type = this.subjectToType;
        const attr = 'subjectTo'+capitalize(type);
        if (this[attr] !== null) {
            switch(type) {
            case SUBJECT_TYPE_DRIVER:
            case SUBJECT_TYPE_CUSTOMER:
            case SUBJECT_TYPE_ENTITY:
            case SUBJECT_TYPE_SUPPLIER:
            case SUBJECT_TYPE_SUBCATEGORY:
                return this[attr].name;
            case SUBJECT_TYPE_USER:
                return this[attr].fullName;
            default:
                throw new Error('Unknown subject to type: ' + type);
            }
        } else {
            return ''; // Nothing selected yet
        }
    }

    contextToText() {
        const type = this.contextType;
        const attr = 'context'+capitalize(type);
        if (this[attr] !== null) {
            switch(type) {
                case CONTEXT_TYPE_ACTIVITY:
                case CONTEXT_TYPE_INCIDENT:
                    return this[attr]?.id;
                case CONTEXT_TYPE_INVOICE:
                    return this[attr].customerReference;
                case CONTEXT_TYPE_TRUCK:
                case CONTEXT_TYPE_TRAILER:
                    return this[attr].licensePlate;
                default:
                    throw new Error('Unknown context type: ' + type);
            }
        } else {
            return ''; // Nothing selected yet
        }
    }

    finish() {
        return this.api.post(this.url + 'finish/', {}).then(() => this.fetch());
    }

    unfinish() {
        return this.api.post(this.url + 'unfinish/', {}).then(() => this.fetch());
    }

    postReview(review) {
        return this.api.post(this.url + 'review/', { review }).then(() => this.fetch());
    }

    unreview(review) {
        return this.api.post(this.url + 'unreview/', { review }).then(() => this.fetch());
    }
};

export class BEPTicketStore extends Store {
    Model = BEPTicket;
    static backendResourceName = 'bepticket';

    sumCategories() {
        const categories = {positive: 0, negative: 0};
        this.models.map(bep => {
            if (bep.meaning === 'positive') {
                categories.positive += 1;
            } else if (bep.meaning === 'negative') {
                categories.negative += 1;
            }
        });
        return categories;
    }

    @action
    fetchAssignedTicketCounts() {
        return this.api.get('/bepticket/count_assigned/').then(res => {
            return res.data;
        });
    }
};

/**
 * Bep tickets have complicated relations based on current user. This creates
 * a bep ticket with the correct allowed relations which you can fetch savely.
 */
export function createBepTicketWithRelations(currentUser, props) {
    const bepRelations = [
        'category', 'category.followupActionTaker', 'category.followupActionCategory', 'documents', 'category.driverBonus', 'bonuses.driver', 'bonuses.driverBonus',

        'entity',

        'subjectFromUser',

        'contextIncident',

        'subjectToUser',
        'subjectToEntity', 'subjectToSubcategory',

        'followupActionTaker', 'followupActionCategory', 'finishedBy',
        'reviewedBy', 'createdBy',

        'followups', 'followups.createdBy', 'followups.performedBy',
        'followups.performedActionCategory',
    ];

    const userCanViewInvoices = hasPermission(['invoice.view_invoice:all', 'invoice.manage_invoice:customer_service', 'invoice.manage_invoice:all']);
    const userCanViewSuppliers = hasPermission(['activity.manage_activity:account_manager', 'activity.manage_activity:all', 'activity.manage_activity:planner', 'asset.manage_assignment:all', 'boekestijn.manage_supplier:all']);
    // const userCanViewTrailers = hasPermission(['activity.view_activity:customer', 'activity.view_activity:all', 'activity.view_activity:customer_service', 'activity.manage_activity:planner', 'activity.manage_activity:all', 'activity.manage_activity:account_manager', 'asset.manage_assignment:all', 'customer.manage_trailer:all', 'invoice.manage_invoice:customer_service', 'invoice.manage_invoice:all', 'activity.export_activity:all']);
    const userCanViewTrucks = hasPermission(['activity.view_activity:all', 'activity.view_activity:customer', 'activity.view_activity:customer_service', 'activity.manage_activity:planner', 'activity.manage_activity:all', 'activity.manage_activity:account_manager', 'asset.manage_assignment:all', 'asset.manage_truck:all', 'invoice.manage_invoice:customer_service', 'invoice.manage_invoice:all', 'activity.export_activity:all']);
    const userCanViewActivities = hasPermission(['activity.view_activity:all', 'activity.view_activity:customer', 'activity.view_activity:customer_service', 'activity.manage_activity:planner', 'activity.manage_activity:all', 'activity.manage_activity:account_manager', 'activity.manage_activity:for_invoice', 'activity.manage_activity:customer_service_for_invoice', 'activity.manage_allocation:account_manager', 'activity.manage_allocation:all', 'invoice.view_invoice:all', 'asset.manage_assignment:all', 'invoice.manage_invoice:customer_service', 'invoice.manage_invoice:all', 'activity.export_activity:all']);
    const userCanViewDrivers = hasPermission(['activity.view_activity:customer', 'activity.view_activity:all', 'activity.view_activity:customer_service', 'activity.manage_activity:planner', 'activity.manage_activity:all', 'activity.manage_activity:account_manager', 'asset.manage_assignment:all', 'asset.manage_driver:all', 'invoice.view_invoice:all', 'invoice.manage_invoice:customer_service', 'invoice.manage_invoice:all', 'activity.export_activity:all']);

    const invoiceRelations = ['contextInvoice'];
    const incidentRelations = ['contextIncident'];
    const supplierRelations =  ['subjectFromSupplier', 'subjectToSupplier'];
    const truckRelations = ['contextTruck'];
    const trailerRelations = ['contextTrailer'];
    const activityRelations = ['contextActivity'];
    const driverRelations = ['subjectFromDriver', 'subjectToDriver'];
    const customerRelations = ['subjectFromCustomer', 'subjectToCustomer'];

    return new class extends BEPTicket {
        buildFetchData(options) {
            const data = super.buildFetchData(options);
            let relations = bepRelations;
            if (userCanViewInvoices) relations = relations.concat(invoiceRelations);
            if (userCanViewSuppliers) relations = relations.concat(supplierRelations);
            if (userCanViewTrucks) relations = relations.concat(truckRelations);
            if (userCanViewActivities) relations = relations.concat(activityRelations);
            if (userCanViewDrivers) relations = relations.concat(driverRelations);
            // relations.concat(incidentRelations);
            // if (userCanViewCustomers) relations = relations.concat(customerRelations);
            data.with = relations.map(this.constructor.toBackendAttrKey).join(',');
            return data;
        }
    } (
        props,
        {
            // {copy-paste-bep-relations}
            relations: [...bepRelations, ...invoiceRelations, ...supplierRelations, ...truckRelations, ...trailerRelations, ...activityRelations, ...driverRelations, ...customerRelations, ...incidentRelations],
        }
    );
}
